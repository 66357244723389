import React from 'react'

import styles from './MobileNavBar.module.scss'
import { NavLink } from 'react-router-dom'
import { ERoutePaths } from 'router/types'
import classNames from 'classnames'
import { HeartStrokeIcon, ListIcon, ShopCartIcon, UserIcon } from 'assets'
import { useAppSelector } from 'hooks/useTypedSelector'

const MobileNavBar = () => {
  const basketSlice = useAppSelector(state => state?.basketSlice)
  const watchlistSLice = useAppSelector(state => state?.watchlistSLice)
  const basketQuality = basketSlice.data?.data?.length
  const watchlistQuality = watchlistSLice.data?.length

  return (
    <div className={styles.wrapper}>
      <NavLink
        to={ERoutePaths.CategoriesPage}
        className={({ isActive }) =>
          isActive ? classNames(styles.wrapper__item, styles.wrapper__item__active) : styles.wrapper__item
        }
      >
        <ListIcon />

        <p>Каталог</p>
      </NavLink>

      <NavLink
        to={ERoutePaths.Saved}
        className={({ isActive }) =>
          isActive ? classNames(styles.wrapper__item, styles.wrapper__item__active) : styles.wrapper__item
        }
      >
        <div className=' relative'>
          {watchlistQuality !== 0 && watchlistQuality !== undefined && (
            <div className=' absolute bg-[#ebb88d] w-4 h-4 text-center flex rounded-full items-center justify-center   -top-2 -right-2 text-white'>
              {watchlistQuality}
            </div>
          )}{' '}
          <HeartStrokeIcon />
        </div>
        <p>Бажане</p>
      </NavLink>

      <NavLink
        to={ERoutePaths.Basket}
        className={({ isActive }) =>
          isActive ? classNames(styles.wrapper__item, styles.wrapper__item__active) : styles.wrapper__item
        }
      >
        <div className=' relative'>
          {basketQuality !== 0 && basketQuality !== undefined && (
            <div className=' absolute bg-[#ebb88d] w-4 h-4 text-center flex rounded-full items-center justify-center   -top-2 -right-2 text-white'>
              {basketQuality}
            </div>
          )}{' '}
          <ShopCartIcon />
        </div>

        <p>Кошик</p>
      </NavLink>

      {/* <NavLink
        to={'/'}
        className={({ isActive }) =>
          isActive ? classNames(styles.wrapper__item, styles.wrapper__item__active) : styles.wrapper__item
        }
      >
        <UserIcon />

        <p>Кабінет</p>
      </NavLink> */}
    </div>
  )
}

export default MobileNavBar
