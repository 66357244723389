import { type FC, useState, useRef } from 'react'
import classNames from 'classnames'

import { ArrowRIghtIcon, CloseIcon, ListIcon, NotFoundImage } from 'assets'

import styles from './Menu.module.scss'
import { useAppDispatch, useAppSelector } from 'hooks/useTypedSelector'
import { fetchCategoryTreeData } from 'store/features/Category/categorySlice'
import { useLockBodyScroll, useOnClickOutside } from 'hooks'

const Menu: FC = () => {
  const [droped, setDroped] = useState<boolean>(false)
  const [renderItem2, setRenderItem2] = useState<any>()
  const ref = useRef(null)

  useOnClickOutside(ref, () => setDroped(false))
  useLockBodyScroll(!droped)

  const dispatch = useAppDispatch()
  const categoryTree = useAppSelector(state => state?.categoryTree)
  const catalogItems = categoryTree.data ? categoryTree.data : []
  const [activeCategory, setActiveCategory] = useState<string | null>(null)

  const handleDropping = () => {
    if (!droped) {
      dispatch(fetchCategoryTreeData())
    }
    setDroped(true)
  }

  const handleDroppingClose = () => {
    setDroped(false)
  }

  const handleMouseOverCategory = (category: any) => {
    setRenderItem2(category.childrens)
    setActiveCategory(category.id)
  }

  // href={`/app/categories?category_id=${element.id}`}

  const renderCatalogItem = catalogItems.map((element: any, index: number) => (
    <a
      href={
        element?.childrens.length > 0 ? `/subcategories/${element.id}` : `/app/categories?category_id=${element.id}`
      }
      key={index}
      className={classNames(styles.wrapper__dropdown__left__item, {
        [styles.wrapper__dropdown__left__item__active]: activeCategory === element.id,
      })}
      onMouseOver={() => handleMouseOverCategory(element)}
    >
      <img className='w-5 h-5' src={element.icon || NotFoundImage} />
      <div>
        <p className={styles.wrapper__dropdown__left__item__text}>{element.name}</p>

        {element?.childrens.length > 0 ? <ArrowRIghtIcon /> : ''}
      </div>
    </a>
  ))

  return (
    <div ref={ref} className={styles.wrapper}>
      <button ref={ref} className={styles.wrapper__catalog}>
        <p
          ref={ref}
          onClick={droped ? handleDroppingClose : handleDropping}
          className=' px-6 py-2 gap-2 flex max-h-9 min-h-[36px] min-w-[136px] max-w-[136px] items-center'
        >
          {' '}
          {!droped ? <ListIcon /> : <CloseIcon />} Каталог
        </p>
      </button>

      <div className={classNames(styles.wrapper__container, { [styles.wrapper__dropdown__droped]: droped })}>
        <div ref={ref} className={styles.wrapper__dropdown}>
          <div className={styles.wrapper__dropdown__left}>
            <h2 className={styles.wrapper__dropdown__left__title}>Категорії</h2>

            {renderCatalogItem}
          </div>

          <div className={styles.wrapper__dropdown__right}>
            <div className={styles.wrapper__dropdown__right__item}>
              {renderItem2 &&
                renderItem2.map((data: any, index: number) => (
                  <div
                    style={{ textTransform: 'capitalize', display: 'flex', flexDirection: 'column', gap: '10px' }}
                    key={index}
                  >
                    <a
                      href={`/app/categories?category_id=${data.id}`}
                      style={{ cursor: 'pointer', color: '#ebb88d', fontWeight: 400, fontSize: '16px' }}
                    >
                      {data.name}
                    </a>
                    {data.childrens?.map((child: any, index: number) => (
                      <a
                        href={`/app/categories?category_id=${child.id}`}
                        style={{ cursor: 'pointer', color: '#4c5563', fontWeight: 300, fontSize: '16px' }}
                        key={index}
                      >
                        {child.name}
                      </a>
                    ))}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Menu
